import React, { useState, useCallback } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import GTM from "@src/services/gtm"
import ErrorPage404 from "@src/pages/404"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveColumns from "@src/components/container-responsive-columns"
import Form, { FormActions } from "@src/components/form"
import FormFieldInputText from "@src/components/form-field-input-text"
import FormFieldInputTextarea from "@src/components/form-field-input-textarea"
import FormFieldInputEmail from "@src/components/form-field-input-email"
import Button from "@src/components/core-button"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import { htmlToJsx } from "@src/components/core-value-html"
import { ImagePropTypes } from "@src/components/core-image"
import { SITE_DOMAIN } from "@src/utils/constants"

const StyledFormActions = styled(FormActions)`
  @media only screen and (${props => props.theme.screen.small.min}) {
    align-items: flex-start;
  }
`
const FormBlock = styled.div`
  width: 100%;
  @media only screen and (${props => props.theme.screen.small.min}) {
    min-width: 75%;
  }
`

const PromoImageWrapper = styled(ImageWrapper)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`
const PromoContent = styled.div`
  position: relative;
  z-index: 1;
  & > img:first-child {
    width: 300px;
    margin-bottom: 2em;
  }
  & > p {
    text-align: justify;
    font-size: 1.25em;
  }
  & > ul {
    font-weight: 500;
  }
`
const PromoBlock = styled.div`
  position: relative;
  padding: 35px 50px;
  color: ${props => props.theme.white};
`

const StyledColumns = styled(ResponsiveColumns)`
  padding-top: 20px;
  justify-items: start;
  & > *:nth-child(2) {
    grid-column: 1 / -1;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-template-columns: minmax(1rem, 1fr) 50fr 50fr minmax(1rem, 1fr);
    column-gap: 20px;
    & > *:first-child {
      grid-column: 3 / 4;
      margin-right: -20px;
    }
    & > *:nth-child(2) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      margin-left: -20px;
    }
  }
`

const PageTemplate = ({ data, path: pageUri }) => {
  const {
    tenAdventures: {
      landingPage: { uri: lpUri, seo: pageSeo, targetPages },
    },
  } = data
  const targetPage =
    (targetPages &&
      targetPages.length &&
      targetPages.find(check => check.uri === pageUri)) ||
    null

  const [formStatus, setFormStatus] = useState(null)

  const generateDataLayer = useCallback(
    () => ({
      "landing-page": lpUri.replace(
        /^\/landing-page\/(.+?)\/$/,
        (match, lpSlug) => lpSlug
      ),
    }),
    [lpUri]
  )

  const handleSubmit = useCallback(() => {
    setFormStatus({
      processing: "Please wait a moment while we submit your inquiry...",
    })
  }, [setFormStatus])
  const handleSubmitError = useCallback(
    error => {
      setFormStatus({ error: error.message })
    },
    [setFormStatus]
  )
  const handleSubmitSuccess = useCallback(
    result => {
      if (result.netlify) {
        setFormStatus({
          success: "Thanks for your interest!",
        })
        GTM.dataLayerPush({
          event: "10a.landing-page.inquiry",
          ...generateDataLayer(),
        })
      } else {
        handleSubmitError({
          message: "There was an error submitting your inquiry.",
        })
      }
    },
    [setFormStatus, handleSubmitError, generateDataLayer]
  )

  if (!targetPage) {
    return <ErrorPage404 />
  }
  const { pageTitle: title, promoText, promoBg, formQuestion } = targetPage
  GTM.dataLayerPushPageInfo({
    template: "landing-page-target",
  })
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, "index"],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <StyledColumns variant="even">
        <FormBlock>
          <h2>{title}</h2>
          <Form
            name={`landing-page-${pageUri.replace(/[^a-z0-9-]/g, "")}`}
            onSubmit={handleSubmit}
            onSubmitError={handleSubmitError}
            onSubmitSuccess={handleSubmitSuccess}
            generateDataLayer={generateDataLayer}
            netlify
          >
            <FormFieldInputText
              name="first_name"
              placeholder="First Name (required)"
              validation={{
                required: "First name field is required",
              }}
            />
            <FormFieldInputText
              name="last_name"
              placeholder="Last Name (required)"
              validation={{
                required: "Last name field is required",
              }}
            />
            <FormFieldInputEmail
              name="email"
              placeholder="Email (required)"
              validation={{
                required: "Email address is required",
              }}
            />
            <FormFieldInputText
              name="number_of_travelers"
              placeholder="Number of travelers"
            />
            <FormFieldInputText
              name="number_of_days"
              placeholder="Number of days you want to travel"
            />
            <FormFieldInputTextarea name="message" placeholder={formQuestion} />
            <StyledFormActions
              submitting={
                formStatus && formStatus.processing ? true : undefined
              }
            >
              {(formStatus?.success && (
                <p className="form-success">{formStatus.success}</p>
              )) ||
                (formStatus?.error && (
                  <p className="form-error">{formStatus.error}</p>
                )) ||
                (formStatus?.processing && (
                  <p className="form-info">{formStatus.processing}</p>
                ))}
              <Button
                type="submit"
                color="yellow-black"
                disabled={
                  formStatus && formStatus.processing ? true : undefined
                }
              >
                Send my inquiry
              </Button>
            </StyledFormActions>
          </Form>
        </FormBlock>
        <PromoBlock>
          <PromoContent>
            <img
              src="/assets/10Adventures-logo-1-light.png"
              alt="10Adventures logo"
              width="2913"
              height="1207"
            />
            {htmlToJsx(promoText)}
          </PromoContent>
          <Image wrapper={PromoImageWrapper} {...promoBg} />
        </PromoBlock>
      </StyledColumns>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      landingPage: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        headerImage: PropTypes.shape({ ...ImagePropTypes }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
        targetPages: PropTypes.arrayOf(
          PropTypes.shape({
            uri: PropTypes.string,
            pageType: PropTypes.string,
            pageTitle: PropTypes.string,
            promoText: PropTypes.string,
            promoBg: PropTypes.shape({ ...ImagePropTypes }),
            formQuestion: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  path: PropTypes.string,
}
export default PageTemplate
export const query = graphql`
  query ($landingPageId: ID!) {
    tenAdventures {
      landingPage(id: $landingPageId, idType: DATABASE_ID) {
        title
        uri
        headerImage {
          ...TenAdventuresGQL_ImageSizesQuery
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
        targetPages {
          uri
          pageTitle
          promoText
          promoBg {
            ...TenAdventuresGQL_ImageSizesQuery
          }
          formQuestion
        }
      }
    }
  }
`
